import { ComponentRef, Directive, EventEmitter, Input, OnInit, Output, Type, ViewContainerRef } from '@angular/core';
import { FormControlStatus } from '@angular/forms';
import { BaseForm, IViewMode, UnsubscribeOnDestroyAdapter } from '@shared/classes';

@Directive({
  selector: '[appDynamicFormComponent]',
})
export class DynamicFormComponentDirective extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() dynamicComponent: Type<any>;

  @Input() set formLinkedManualRelations(linkedManualRelations: any[]) {
    this.setLinkedRelations(linkedManualRelations);
  }

  _viewModeOnly: boolean;
  @Input() set viewModeOnly(value: boolean) {
    this.setviewModeOnly(value);
  }

  _viewMode: IViewMode;
  @Input() set viewMode(value: IViewMode) {
    this.setviewMode(value);
  }


  linkedManualRelations: any[];
  @Input() set formData(data: any) {
    this.setData(data);
  }
  data: any;
  @Input() set showSaveAndClose(state: boolean) {
    this.setShowSaveAndClose(state);
  }
  showSaveAndCloseButton: boolean;

  @Input() set popupOptions(options: boolean) {
    this.setPopupOptions(options);
  }
  myPopupOptions: any;

  @Output() formChanges: EventEmitter<any> = new EventEmitter();
  @Output() formStatusChanged: EventEmitter<FormControlStatus> = new EventEmitter();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();
  @Output() formSubmitSave: EventEmitter<any> = new EventEmitter();

  component: ComponentRef<any>;

  constructor(public viewContainerRef: ViewContainerRef) {
    super();
  }
  ngOnInit(): void {
    this._renderViewComponent();
  }
  _renderViewComponent() {
    this.component = this.viewContainerRef?.createComponent(this.dynamicComponent);
    this.subs.sink = this.component?.instance?.formChanges?.subscribe((data) => {
      this.formChanges.emit(data);
    });
    this.subs.sink = this.component?.instance?.formStatusChanged?.subscribe((data) => {
      this.formStatusChanged.emit(data);
    });
    this.subs.sink = this.component?.instance?.formSubmit?.subscribe((data) => {
      this.formSubmit.emit(data);
    });
    this.subs.sink = (this.component?.instance as BaseForm<any>)?.formSubmitSave?.subscribe((data) => {
      this.formSubmitSave.emit(data);
    });
    this.setData(this.data);
    this.setShowSaveAndClose(this.showSaveAndClose);
    this.setLinkedRelations(this.linkedManualRelations);
    this.setPopupOptions(this.myPopupOptions);
    this.setviewModeOnly(this._viewModeOnly);
    this.setviewMode(this._viewMode);
  }
  setData(data) {
    this.data = data;
    if (this.component?.instance) (this.component.instance as BaseForm<any>).formData = data;
  }
  setLinkedRelations(linkedRelations) {
    this.linkedManualRelations = linkedRelations;
    if (this.component?.instance) (this.component.instance as BaseForm<any>).linkedManualRelations = linkedRelations;
  }
  setShowSaveAndClose(state: boolean) {
    this.showSaveAndCloseButton = state;
    if (this.component?.instance) (this.component.instance as BaseForm<any>).showSaveAndClose = state;
  }
  setPopupOptions(options: any) {
    this.myPopupOptions = options;
    if (this.component?.instance) (this.component.instance as BaseForm<any>).popupOptions = options;
  }
  setviewMode(viewMode: IViewMode) {
    this._viewMode = viewMode;
    if (this.component?.instance) (this.component.instance as BaseForm<any>).manualViewMode = viewMode;
  }
  setviewModeOnly(viewModeOnly: boolean) {
    this._viewModeOnly = viewModeOnly;
    if (this.component?.instance) (this.component.instance as BaseForm<any>).viewModeOnly = viewModeOnly;
  }
}
