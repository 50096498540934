import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  FilterItem,
  IAction,
  IColumn,
  generateAlphaNumericUnderScoreCodeWithTimeStamp,
  getProp,
} from '@shared/classes';
import { AppDialogService, IConfirmationConfig, IConfirmationPosition } from '@shared/services/app-dialog.service';
import { EntityTypesDataService } from 'app/modules/entity-module/services/data/entity-types-data.service';
import { FieldItemTypeFormComponent } from 'app/modules/global-configuration/fields/field-item-form-for-type/field-item-form.component';
import { FieldsDataService } from 'app/modules/global-configuration/services/data/fields-data.service';
import { omit } from 'lodash-es';
import { Observable, Subject, debounceTime, distinctUntilChanged, of, switchMap, takeUntil } from 'rxjs';
import { FieldPopupFormComponent } from './field-popup-form/field-popup-form.component';
@Component({
  selector: 'app-fields-table-selector',
  templateUrl: './fields-table-selector.component.html',
  styleUrls: ['./fields-table-selector.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: FieldsTableSelectorComponent }],
})
export class FieldsTableSelectorComponent extends DynamicComponentBase implements OnInit {
  dialogComponent = FieldItemTypeFormComponent;
  selectedItems = [];
  tableItems = [];
  searchQ;
  projectionFields = ['id', 'code', 'options', 'name', 'fieldName', 'label', 'type', 'key', 'recordStatus'];
  private searchText$ = new Subject<string>();
  searchItems$: Observable<any[]>;
  cols: IColumn[] = [
    { key: 'name', name: 'Name', dataType: DataTypeEnum.Text },
    // { key: "key", name: "Key", dataType: DataTypeEnum.Text },
    // { key: "label", name: "Label", dataType: DataTypeEnum.Text },
    { key: 'type', name: 'Type', dataType: DataTypeEnum.Badge },
  ];
  infoActions: IAction[] = [
    {
      id: 1,
      tooltipText: 'Info',
      icon: 'pi pi-info',
      color: 'success',
      command: this.onInfo.bind(this),
      buttonStyle: 'text',
    },
  ];
  actions: IAction[] = [
    {
      id: 2,
      tooltipText: 'Edit',
      icon: 'pi pi-pencil',
      color: 'success',
      command: this.onEdit.bind(this),
      buttonStyle: 'text',
    },
    {
      id: 3,
      tooltipText: 'Delete',
      icon: 'pi pi-trash',
      color: 'danger',
      command: this._delete.bind(this),
      buttonStyle: 'text',
    },
  ];
  showAddItem: Boolean = false;
  showAddItemAction: IAction = {
    id: 1,
    label: 'Add New Field',
    buttonType: 'button',
    command: this.onAddItem.bind(this),
    icon: 'pi pi-plus',
    color: 'info',
    buttonClass: 'white-space-nowrap',
  };
  formType: 'create' | 'update' | 'local' = 'local';
  @Input() entityType: string;
  // requestService : BaseRequestControllerService<any>;
  constructor(
    private requestService: FieldsDataService,
    private dialogService: AppDialogService,
    private entityTypeService: EntityTypesDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.onFilterInputChange();
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
      this.tableItems = Object.keys(changes ?? {}).map((key) => {
        return changes[key];
      });

    });
  }

  setInputOptions(): void { }
  search(keywords: string) {
    this.searchText$.next(keywords);
  }

  onSelectItem(ev) {
    const event = ev.value;

    this.tableItems.push({
      ...omit(event, 'code', 'id', 'recordStatus'),
      fieldCategory: 'INSTANCE',
      key: generateAlphaNumericUnderScoreCodeWithTimeStamp(event?.label || event?.name),
    });
    this.searchQ = '';
    this.control.patchValue(this.tableItems);
  }

  onActionClick(action: any): void {
    switch (action.action.id) {
      case PageActionsEnum.Delete:
        this._delete(action.index);
        break;
      default:
        break;
    }
  }
  _delete(id) {
    if (this.viewMode == 'edit') {
      let config: IConfirmationConfig = new IConfirmationConfig();

      config.position = IConfirmationPosition.top;

      config.header = 'Are you sure you want to delete this field?';
      config.message = 'This may cause conflict in entities connected to this entity type.';

      this.dialogService.confirm(
        {
          accept: () => {
            if (this.entityType) {
              this.entityTypeService.getDynamicFieldsContainsNotNull(this.entityType, this.tableItems[id]?.code).subscribe(res => {
                if (res?.data?.length) {
                  let config: IConfirmationConfig = new IConfirmationConfig();

                  config.position = IConfirmationPosition.top;

                  config.header =
                    'This field cannot be deleted because it is referenced in existing records with values attached.';
                  config.message =
                    'Records containing this field: ' + res?.data;

                  this.dialogService.confirm(
                    {
                      accept: () => {
                      }, reject: null,
                      cancel: null,

                    },
                    config
                    ,
                    false
                  );
                } else {
                  this.tableItems.splice(id, 1);
                  this.control.patchValue(this.tableItems);
                }
              });
            } else {
              this.tableItems.splice(id, 1);
              this.control.patchValue(this.tableItems);
            }
          },
          reject: () => { },
          cancel: () => { },
        },
        config
      );
    } else {
      this.tableItems.splice(id, 1);
      this.control.patchValue(this.tableItems);
    }
  }
  getElementValue(key: string, data: any) {
    let value = data[key];
    value = getProp(data, key);
    return value;
  }
  onAddItem() {
    this.dialogService.showDialog(
      FieldPopupFormComponent,
      'Add New Field',
      (e) => {
        if (e) {
          let item = {};
          if (this.formType == 'local') {
            if (this.viewMode == 'create' || this.resetFieldCheckVisable) {
              (e as { createItems: { key: string; value: any }[] }).createItems.forEach((element) => {
                item[element.key] = element.value;
              });
            } else if (this.viewMode == 'edit') {
              (e as { key: string; value: any }[]).forEach((element) => {
                item[element.key] = element.value;
              });
            }
          }

          this.searchQ = '';
          this.showAddItem = false;

          this.tableItems.push(item);
          this.control.patchValue(this.tableItems);
        }
      },
      {
        data: {
          dynamicViewComponent: this.dialogComponent,
          dataService: this.requestService,
          submitType: 'local',
          // viewModeOnly: true,
          data: { name: this.searchQ, fieldCategory: 'INSTANCE' },
        },
      }
    );
  }
  onEdit(rowIndex) {
    const row = this.tableItems[rowIndex];
    const rowCode = this.tableItems[rowIndex]?.code;

    this.dialogService.showDialog(
      FieldPopupFormComponent,
      'Edit Field',
      (e) => {
        if (e) {
          let item: any = rowCode ? { code: rowCode } : {};
          if (this.formType == 'local') {
            if (this.viewMode == 'create') {
              item = { ...this.tableItems[rowIndex] };
              (e as { createItems: { key: string; value: any }[] }).createItems.forEach((element) => {
                item[element.key] = element.value;
              });
            } else if (this.viewMode == 'edit') {
              item = { ...this.tableItems[rowIndex] };
              (e as { key: string; value: any }[]).forEach((element) => {
                item[element.key] = element.value;
              });
            }
          }

          this.searchQ = '';
          this.showAddItem = false;

          this.tableItems[rowIndex] = item;
          this.tableItems = [...this.tableItems];
          this.control.patchValue(this.tableItems);
        }
      },
      {
        data: {
          dynamicViewComponent: this.dialogComponent,
          dataService: this.requestService,
          submitType: 'local',
          // viewModeOnly: true,
          data: { ...row, fromCreate: false },
        },
      }
    );
  }

  onInfo(rowIndex) {
    const row = this.tableItems[rowIndex];
    row.expanded = !row?.expanded;
    this.tableItems = [...this.tableItems];
    this.control.patchValue(this.tableItems);
  }

  onFilterInputChange() {
    this.searchText$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((keywords) => {
          return this.requestService.search(
            { page: 0, size: 20 },
            {
              projectionFields: this.projectionFields,
              filters: this.getFilters(keywords),
            }
          );
        })
      )
      .subscribe((res) => {
        if (res.content.length <= 0) {
          this.showAddItem = true;
        } else {
          this.showAddItem = false;
        }

        this.searchItems$ = of(res.content);
      });
  }

  getFilters(keywords): FilterItem[] {
    return keywords
      ? [
        { property: 'name', operation: 'CONTAINS', value: keywords },
        { property: 'fieldCategory', operation: 'EQUAL', value: 'TEMPLATE' },
      ]
      : [{ property: 'fieldCategory', operation: 'EQUAL', value: 'TEMPLATE' }];
  }

  getFormValue(value) {
    return new FormControl(value);
  }

  isObject(value: any): boolean {
    return (
      value &&
      ((typeof value === 'object' && value.constructor === Object) ||
        (Array.isArray(value) && value.length && typeof value[0] === 'object' && value[0].constructor === Object))
    );
  }
}
enum PageActionsEnum {
  Delete,
}
