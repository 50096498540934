import { Component, Type } from '@angular/core';
import { BasePage, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { FieldsDataService } from 'app/modules/global-configuration/services/data/fields-data.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-field-popup-form',
  templateUrl: './field-popup-form.component.html',
  styleUrls: ['./field-popup-form.component.scss'],
})
export class FieldPopupFormComponent extends BasePage {
  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };
  viewForm: Type<any>;
  requestService: BaseRequestControllerService<any>;
  submitType: 'create' | 'update' | 'local' = 'local';
  itemId = null;
  fromType = false;
  viewModeOnly;
  data: any = null;
  fromCreate: boolean = true;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fieldsDataService: FieldsDataService
  ) {
    super();
    this.viewForm = this.config?.data?.dynamicViewComponent;
    this.requestService = this.config?.data?.requestService;
    this.submitType = this.config?.data?.submitType ?? this.submitType;
    this.itemId = this.config?.data?.itemId ?? this.itemId;
    this.data = this.config?.data?.data ?? this.data;
    this.fromType = this.config?.data?.fromType ?? this.fromType;
    this.viewModeOnly = this.config?.data?.viewModeOnly ?? this.viewModeOnly;
    this.fromCreate = this.config?.data?.fromCreate ?? this.fromCreate;
  }

  onSubmit(data, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    switch (this.submitType) {
      case 'create':
        this.requestService.create(data, this.apiOptions).subscribe((res) => {
          this.ref.close(res.data);
        });
        break;
      case 'update':
        this.requestService.patchSingleUpdate(data, this.itemId, this.apiOptions).subscribe((res) => {
          this.ref.close(res.data);
        });
        break;
      default:
        this.ref.close(data);
        break;
    }
  }
}
