import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, FieldDto, cleanNotAlphaNumericUnderScore } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-field-item-form-type',
  templateUrl: './field-item-form.component.html',
  styleUrls: ['./field-item-form.component.scss'],
})
export class FieldItemTypeFormComponent extends BaseForm<FieldDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'FIELD_TEMPLATE');
  }

  typeDataType: DataTypeEnum = DataTypeEnum.Badge;
  @Input() isOptionsCollapsed: boolean = false;
  @Input() actions;
  @Input() index;
  @Input() rowData;
  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({
        ...this.formGroup.getRawValue(),
        key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value),
      })
      : this.getChangedFormValues({
        ...this.formGroup.getRawValue(),
        key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value),
      }).updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;

    this.formGroup?.patchValue({
      key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value),
    });

    // this.isOptionsCollapsed = !data?.expanded;

    if (data?.code) {
      this.formGroup?.controls?.type.disable();
    }
  }

  toggleOptions(): void {
    this.isOptionsCollapsed = !this.isOptionsCollapsed;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      // label: new FormControl(null, Validators.required),
      name: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z_$][a-zA-Z0-9_\s$]*$/)]),
      key: new FormControl(null),
      type: new FormControl(null, Validators.required),
      options: new FormControl(null),
      fieldCategory: new FormControl('TEMPLATE'),
    });
  }
}
