<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="'modules.entity.entityType.fields.name.label' | translate: 'Name'"
        [placeholder]="'modules.entity.entityType.fields.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'modules.entity.entityType.fields.category.label' | translate: 'Category'"
        [placeholder]="'modules.entity.entityType.fields.category.placeholder' | translate: 'Select Category'"
        [translationKeyPrefix]="moduleFieldString + '.category.enums'"
        [name]="'multi-dropdown'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="categories"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.category"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-icon-selector
        [label]="'modules.entity.entityType.fields.icon.label' | translate: 'Icon'"
        [placeholder]="'modules.entity.entityType.fields.icon.placeholder' | translate: 'Select Icon'"
        [control]="formGroup?.controls?.icon"
        [viewMode]="fieldViewMode"
      >
      </app-icon-selector>
    </div>
    <!-- <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Tree View Mode'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="treeViewModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.treeViewMode"
      [badgeView]="true"
      [viewMode]="fieldViewMode"
    ></app-drop-down-input>
  </div> -->
    <!-- <div class="col-12">
  <app-form-repeater
    [viewMode]="fieldViewMode"
    [title]="'Dynamic Fields'"
    [cols]="formRepeaterFields"
    [control]="formGroup?.controls.dynamicFields"
    [showOptionsAction]="true"
  ></app-form-repeater>
</div> -->
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'description'"
        [label]="'modules.entity.entityType.fields.description.label' | translate: 'Description'"
        [placeholder]="'modules.entity.entityType.fields.description.placeholder' | translate: 'Select Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <div class="col-12">
      <app-fields-table-selector
        [viewMode]="fieldViewMode"
        [control]="formGroup?.controls.fields"
        [entityType]="data?.code"
        [label]="'modules.entity.entityType.fields.dynamics.label' | translate: 'Entity Field'"
        [placeholder]="'modules.entity.entityType.fields.dynamics.placeholder' | translate: 'Enter Entity Fields'"
      ></app-fields-table-selector>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
